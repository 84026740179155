#topNav .logout-btn{
    border-left: 1px solid white !important;
    padding-left: 15px;
    margin-left: 17px;
}
.logout-btn button{
    background: transparent !important;
    color: white;
    border: none !important;  
    cursor: pointer;
    transition: 0.5s opacity;
}
.logout-btn button:hover{
    opacity: 0.4;
}
#home{
    position: relative;
}
#home .mint-btn img{
    width: 300px;
    box-shadow: 1px 1px 8px #4e2712;
    border-radius: 5px;
    cursor: pointer;
}
#home .mint-btn button{
    z-index: 2;
    background: transparent;
    box-shadow: none;
    border: none;
    font-size: 1.5rem;
    text-transform: uppercase;
    color: #ebd1c4;
    position: absolute;
    left: 50%;
    cursor: pointer;
    top: 45%;
    transform: translate(-50%,-50%);
    width: 100%;
}
#home .mint-btn{
    position: relative;
    height: 100%;    
    width: 300px;
    display: inline-block;
}

#home .mint-btn-section{
    transition: 0.6s all;
}
#home .mint-btn-section:hover{
    transform: scale(1.05);
}

#home .mint-btn small{
    position: absolute;
    bottom: -20px;
    font-size: 1rem;
    left: 0;
    right: 0;
    text-align: center;
}
#headerSocial{
    display: inline-block;
    margin-left: 25px;
    margin-top: -35px;
    vertical-align: middle;
}
#headerSocial img{
    width: 20px;
}
#headerSocial a{
    margin-right: 15px;
    transition: 0.4s all;
}
#headerSocial a:hover{
    opacity: 0.4;
}
.topSocial img{
    width: 20px;
}
#headerBG{
    background: url('../assets/img/picture/bg1.png') no-repeat center center ; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position:center 0px !important;
    position: absolute;
    top: 0;
    left: 0;
    right:0;
    width: 100vw;
    height: 80vh;
    margin-top: 110px;
    z-index: -1;
}


#home{
    padding-top: 80px;
    width: 100%;
    min-height: 100vh;
}


#leftSide{
    width: 55%;
    height: 100%;
    padding-left: 8%;
}

#rightSide{
    width: 30%;
    height: 100%;
    padding-right: 8%;
}


#aboutSection{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 80px;

}

#aboutLeft{
    flex:2;
    display: flex;
    flex-wrap: wrap;
    padding-right: 10%;
    margin-left: -1%;
}
#aboutRight{
    flex:3;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

#aboutSection h1{
    margin-bottom: 1em;
    line-height: 30px;
}

#aboutLeft img {
    flex: 0 0 31.333333%;
    margin: 1%;
    width: 33px;
}


#whyCanvas{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    
}
#whySection{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#whySection .container{
    text-align: center;
}

#whySection h1{
    margin-bottom: 15px;
}

#whySection ul{
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
}
#whySection li{
    padding: 25px 25px;
    background: linear-gradient(to right, #0c0806 0%, #29160c 100%);
    margin: 10px;
    border: 3px solid #594a3c;
}
#whySection li:first-child{
    margin-left:0;
}
#whySection li:last-child{
    margin-right:0;
}

#imageWrapper{
    flex-direction: column;
}

#imageWrapper img{
    z-index: 9;
    width: 100%;    
    height: auto;
    margin-bottom: 50px;
}

#leftSide h1{
    position: relative;
    margin-top: 15px;
}

#leftSide h2{
    max-width: 90%;
    padding: 15px 0 50px 0;
    line-height: 1.5rem;
}
.btnWrapper{
    max-width: 190px;
}

#bottomSpan{
    display:flex;    
    margin-bottom: 80px;
}
.spanItem{
    margin-right: 80px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
}
.spanItem label{
    font-size: 13px;
    font-weight: 300;
}
.spanItem span{
    font-size: 18px;
    font-weight: 700;
}

#imageWrapper{
    display: flex;
    justify-content: flex-end;
    position: relative;
    z-index: 9;
}

#stepSection{
    padding: 80px 0px;
    margin-top: -250px;
}

#aboutSection button{
    width: auto;
    margin-top: 35px;
    padding: 13px 25px;
}
#stepSection h1 span{
    color: #3b46ff;
}

#faq h1{
    text-align: center;
}

@media (max-width: 960px) {

    #home .mint-btn small{
        position: absolute;
        bottom: -20px;
        left: 0;
        right: 0;
        text-align: center;
    }
    #home{
        flex-direction: column;
    }
    #leftSide h2{
        padding: 15px 0 50px 0;
        text-align: center;
        max-width: 100%;
    }
    #imageWrapper{
        width: 80%;
        justify-content: center;
        margin:auto;
    }
    #bottomSpan{
        justify-content: center;
    }
    .spanItem{
        margin: 0px 40px;
    }
    #leftSide{
        width: 100%;
        height: 100%;
        padding-left: 0;
        text-align: center;
        margin-top: 25%;
    }
    #headerSocial{
        display: block;
        margin-left: 0px;
        margin-top: 70px;
    }
    #headerSocial a{
        margin-left: 8px;
        margin-right: 8px;
    }
    #rightSide{
        width: 100%;
        order: 1;
        margin-top: 30px;
    }
    .btnWrapper{
        margin: 0 auto;
    }
    #aboutSection{
        background-size: cover;
    }
}

@media (max-width: 760px) {
    #mintCount{
        bottom: -35px !important;
        height: 20vh !important;
    }
    #topLogo{
        display: none;
    }
    #burgerIcon{
        margin-top: -20px;
        margin-right: 15px;
    }
    #headerBG{
        margin-top: 70px ;
    }
    .ag-smoke-block{
        margin-bottom: 11vh !important;
    }
    .ag-smoke_img{
        min-width: 800px;
    }
    #aboutSection{
        flex-direction: column;
        margin-top: 15px;
    }
    #aboutLeft{
        padding:0;
        width: 50%;
    }
    #aboutSection h1{
        margin-top:25px;
    }
    #gear2, #gear1{
        opacity: 0.2;
    }
    #faqTop, #faqBottom{
        width: 90% !important;
    }
    #faqContent{
        width: 85% !important;
    }
    #faqBottom{
        bottom: -35px;
    }
    .teamItem{
        flex: 0 0 50% !important;
    }
    .teamItem.hidden{
        display: none;
    }
    #whySection ul{
        flex-direction: column;
    }
  
}

@media (max-width: 520px) {
    body, html{
        overflow-x: hidden !important;
    }
    #faqBanner{
        width: 80% !important;
    }
    #faqBannerSection h1{
        top: 30px !important;
        font-size: 1.4rem;
    }
    #faqBottom{
        bottom: -1vh !important;
    }
    #roadmap main{
        width: 260px !important;
        min-width: 260px;
    }
    #roadmapBanner{
        width: 300px;
        height: 15px;
    }
    #roadmapBanner #bannerStart{
        height: 55px;
    }
    #line{
        width: 80% !important;
    }  
    #whyCanvas{
        width: auto;
        height: 100%;
        margin-top: -150px;
    }
    #whySection{
        margin-top: 250px !important;
        padding-bottom: 180px;
    }
    #mint{
        top: -10vh !important;
    }
   .font60{
    font-size: 3.1rem;
   }
}

#whySection{
    position: relative;
    min-height: 100vh;
    margin-top: 120px;
    margin-bottom: 100px;
}


#promptModal .modal-body, #responseModal .modal-body{
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    position: absolute;
    width: 100%;
    height: 310px;
}
.promptBody input{
    width: 80%;
    border: 3px solid #866643;
    background: #e9d8c4;
    margin-top: 6px;
    padding: 4px;
    text-align: center;
}
.promptBody{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
#promptErr{
    width: 80%;
    color: white;
    background: #7e0f0f;
    padding: 3px 14px;
    text-align: center;
    display: none;
}
.promptBody p{
    color: #ebd1c4;
    text-align: center;
    text-shadow: 6px 5px 5px #180900;
}

.promptBtnSection{
    display: flex;
    width: 80%;
    justify-content: flex-end;
    margin-top: 15px;
}
#mintBtn{
    background: transparent;
    border: none;
    transition: 0.5s opacity;
    color: #ffb176;
    font-size: 1.5rem;
}
#mintBtn:hover{
    opacity: 0.5;
}
.promptClose{
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    color: white;
}
#promptModal.modal-dialog, #responseModal.modal-dialog{
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) !important;
    margin: 0 !important;
    width: 495px;
    height: 350px;
}


#gear1{
    position: absolute;
    right: -150px;
    width: 250px;
    animation: gearrotate 60s linear infinite;
}
#gear2{
    position: absolute;
    left: -80px;
    top: 350px;
    width: 160px !important;
    animation: gearrotate 5s linear infinite;
}



@keyframes gearrotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}





#ship1{
    position: absolute;
    right: -15%;
    top: 35%;
    width: 80px;
    animation: shipleft 90s linear infinite;
    z-index: 100;
}
#ship2{
    position: absolute;
    left: -30%;
    top: 15%;
    width: 101px;
    animation: shipright 50s linear infinite;
    z-index: 1;
}

@keyframes shipleft {
    0%, 100% {
      right: 0%
    }
    1%{opacity: 1;}
    98% {
        right: 115%;
        opacity: 0.6;
    }
    99%{
        opacity: 0;
        right:-15%;
    }
  }
@keyframes shipright {
    0%, 100% {
      left: -30%;
    }
    1%{opacity: 1;}
    25%{
        left:-15%;
    }
    98% {
        left: 115%;
        opacity: 0.5;
    }
    99%{
        opacity: 0;
        left:-15%;
    }
  }
.ag-format-container {
    width: 1142px;
    margin: 0 auto;
  }
  
  
  .ag-smoke-block {
    width: 100%;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    background: transparent;
    left: 0;
    height: 100%;
    bottom: 0;
    margin-bottom: 3%;
  }
  
  .ag-smoke_img {
    width: 100%;
    height: auto;
  
    z-index: 10;
    position: absolute;
    bottom: 0;
  }
  .ag-smoke_img__left {
    left: 0;
    -webkit-animation: an-smoke-left 30s linear infinite;
    -moz-animation: an-smoke-left 30s linear infinite;
    -o-animation: an-smoke-left 30s linear infinite;
    animation: an-smoke-left 30s linear infinite;
    filter: sepia(0.5);
  }
  .ag-smoke_img__right {
    right: 0;
    -webkit-animation: an-smoke-right 30s linear infinite;
    -moz-animation: an-smoke-right 30s linear infinite;
    -o-animation: an-smoke-right 30s linear infinite;
    animation: an-smoke-right 30s linear infinite;
    filter: sepia(0.3);
  }
  
  @-webkit-keyframes an-smoke-left {
    0%, 100% {
      -webkit-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
    }
    25% {
      -webkit-transform: translate(-10%,0%);
      transform: translate(-10%, 0%);
    }
    50% {
      -webkit-transform: translate(-20%, 0%);
      transform: translate(-20%, 0%);
    }
    75% {
      -webkit-transform: translate(-10%, 0%);
      transform: translate(-10%, 0%);
    }
  }
  @-moz-keyframes an-smoke-left {
    0%, 100% {
      -moz-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
    }
    25% {
      -moz-transform: translate(-10%,0%);
      transform: translate(-10%, 0%);
    }
    50% {
      -moz-transform: translate(-20%, 0%);
      transform: translate(-20%, 0%);
    }
    75% {
      -moz-transform: translate(-10%, 0%);
      transform: translate(-10%, 0%);
    }
  }
  @-o-keyframes an-smoke-left {
    0%, 100% {
      -o-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
    }
    25% {
      -o-transform: translate(-10%,0%);
      transform: translate(-10%, 0%);
    }
    50% {
      -o-transform: translate(-20%, 0%);
      transform: translate(-20%, 0%);
    }
    75% {
      -o-transform: translate(-10%, 0%);
      transform: translate(-10%, 0%);
    }
  }
  @keyframes an-smoke-left {
    0%, 100% {
      -webkit-transform: translate(0%, 0%);
      -moz-transform: translate(0%, 0%);
      -o-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
    }
    25% {
      -webkit-transform: translate(-10%, 0%);
      -moz-transform: translate(-10%, 0%);
      -o-transform: translate(-10%, 0%);
      transform: translate(-10%, 0%);
    }
    50% {
      -webkit-transform: translate(-20%, 0%);
      -moz-transform: translate(-20%, 0%);
      -o-transform: translate(-20%, 0%);
      transform: translate(-20%, 0%);
    }
    75% {
      -webkit-transform: translate(-10%, 0%);
      -moz-transform: translate(-10%, 0%);
      -o-transform: translate(-10%, 0%);
      transform: translate(-10%, 0%);
    }
  }
  
  @-webkit-keyframes an-smoke-right {
    0%, 100% {
      -webkit-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
    }
    25% {
      -webkit-transform: translate(10%,0%);
      transform: translate(10%, 0%);
    }
    50% {
      -webkit-transform: translate(20%, 0%);
      transform: translate(20%, 0%);
    }
    75% {
      -webkit-transform: translate(10%, 0%);
      transform: translate(10%, 0%);
    }
  }
  @-moz-keyframes an-smoke-right {
    0%, 100% {
      -moz-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
    }
    25% {
      -moz-transform: translate(10%,0%);
      transform: translate(10%, 0%);
    }
    50% {
      -moz-transform: translate(20%, 0%);
      transform: translate(20%, 0%);
    }
    75% {
      -moz-transform: translate(10%, 0%);
      transform: translate(10%, 0%);
    }
  }
  @-o-keyframes an-smoke-right {
    0%, 100% {
      -o-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
    }
    25% {
      -o-transform: translate(10%,0%);
      transform: translate(10%, 0%);
    }
    50% {
      -o-transform: translate(20%, 0%);
      transform: translate(20%, 0%);
    }
    75% {
      -o-transform: translate(10%, 0%);
      transform: translate(10%, 0%);
    }
  }
  @keyframes an-smoke-right {
    0%, 100% {
      -webkit-transform: translate(0%, 0%);
      -moz-transform: translate(0%, 0%);
      -o-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
    }
    25% {
      -webkit-transform: translate(10%, 0%);
      -moz-transform: translate(10%, 0%);
      -o-transform: translate(10%, 0%);
      transform: translate(10%, 0%);
    }
    50% {
      -webkit-transform: translate(20%, 0%);
      -moz-transform: translate(20%, 0%);
      -o-transform: translate(20%, 0%);
      transform: translate(20%, 0%);
    }
    75% {
      -webkit-transform: translate(10%, 0%);
      -moz-transform: translate(10%, 0%);
      -o-transform: translate(10%, 0%);
      transform: translate(10%, 0%);
    }
  }
  
  
  @media only screen and (max-width: 767px) {
    .ag-format-container {
      width: 96%;
    }
  
  }
  
  @media only screen and (max-width: 639px) {
  
  }
  
  @media only screen and (max-width: 479px) {
  
  }
  @media only screen and (max-height: 900px) {
    
        #mint{
            top: 20vh;
        }

    }

  @media (min-width: 768px) and (max-width: 979px) {
    .ag-format-container {
      width: 750px;
    }
  
  }
  
  @media (min-width: 980px) and (max-width: 1161px) {
    .ag-format-container {
      width: 960px;
    }
  
  }
  @media (min-width: 980px) and (max-width: 1161px) {
    .ag-format-container {
      width: 960px;
    }
  
  }



  .dropdown {
    display: block;
    width: 100%;
    max-width: 500px;
    margin: auto;
  }
  .dropdown + .dropdown {
    margin-top: 15px;
  }
  
  .dropdown__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding: 15px;
    box-sizing: border-box;
    font: 600 14px/22px sans-serif;
    background-color: #156a87;
    cursor: pointer;
    transition: background 0.3s ease;
    will-change: background;
  }
  .dropdown__top:hover {
    background: #0d5871;
  }
  .dropdown__top::after {
    content: "";
    font: 20px "FontAwesome";
    transition: transform 0.5s ease;
    will-change: transform;
  }
  
  .open .dropdown__top::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  
  .dropdown__btm {
    background: #f2f2f2;
    color: #555;
    font-size: 15px;
    line-height: 1.4;
    box-sizing: border-box;
    padding: 15px;
    display: none;
  }

#team{
    margin-top: 120px;
}
#teamContent{
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
}

.teamItem{
    flex: 0 0 25%;
    margin-bottom: 30px;
}

.teamItem img{
    width: 100%;
}

.teamItem h2{
    font-size: 18px;
    margin-top: 8px;
}
.teamItem small{
    opacity: 0.7;
}
#faq{
    margin-top: 150px;
    position: relative;
    margin-bottom: 150px;
    filter: brightness(0.8) ;
}
#faq h1{
    margin-bottom: 30px;

}


#roadmapBanner{
    text-align: center;
    min-width: 300px;
    max-width: 500px;
    margin: auto;
    position: relative;
}

#roadmapBanner h1{
    color: #e5bba6;
    text-transform: uppercase;
    text-shadow: 0 0 9px #3a1302;
}

#roadmapBanner #bannerStart{
    width: 100%;
    position: absolute;
    top: -8px;
    left: 0;
    z-index: -1;
}

#mint{
    position: absolute;
    z-index: -2;
    left: 50%;
    top: 23vh;
    width: 150px;
    transform: translate(-50%, -50%);
    filter: contrast(1.5);
}

#roadmap{
    position: relative;
}

#roadmap main {
    min-width: 300px;
    max-width: 500px;
    margin: auto;
    position: relative;
  }
  
  #roadmap p {
    font-size: 1em;
    line-height: 1.75em;
    border-top: 3px solid;
    -o-border-image: linear-gradient(to right, #0f0600 0%, #492e1e 100%);
       border-image: linear-gradient(to right, #0f0600 0%, #492e1e  100%);
    border-image-slice: 1;
    border-width: 3px;
    margin: 0;
    padding: 40px;
    counter-increment: section;
    position: relative;
    color: #cfc3b7;
  }
  #roadmap span{
    font-size: 0.8rem;

  }
  #roadmap .title{
      font-size: 1.5rem;
  }
  #roadmap .subtitle{
      color: #a16238;
  }
  #roadmap p:before {
    content: counter(section);
    position: absolute;
    border-radius: 50%;
    padding: 10px;
    height: 1.25em;
    width: 1.25em;
    background-color: #421800;
    text-align: center;
    line-height: 1.25em;
    color: #ffffff;
    font-size: 1em;
  }
  
  #roadmap p:nth-child(odd) {
    border-right: 3px solid;
    padding-left: 0;
  }
  #roadmap p:nth-child(odd):before {
    left: 100%;
    margin-left: -20px;
  }
  #roadmap p:last-child{
      padding-bottom: 80px;
  }
  #roadmap p:nth-child(even) {
    border-left: 3px solid;
    padding-right: 0;
  }
  #roadmap p:nth-child(even):before {
    right: 100%;
    margin-right: -20px;
  }
  
  #roadmap p:first-child {
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  
  #roadmap p:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  
#line{
    background: url('../assets/img/picture/line.png') repeat center center ; 
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    background-position: center 0px !important;
    width: 100%;
    height: 15px;
    margin-top: 0;
     min-width: 300px;
    max-width: 500px;
    margin: auto;
}


#faqTop{
    width: 700px;
    position: absolute;
    left: 0;
    right: 0;
    filter: contrast(1.4);
    top: -30px;
    margin: auto;
    z-index: 10;
}

#faqContent{
    background: url('../assets/img/picture/faqContent.png') repeat center center ; 
    background-size: contain;
    background-position: center 141px !important;
    width: 100%;
    height: 100%;
    max-width: 600px;
    margin: auto;
    padding-top: 80px;
    padding-bottom: 80px;
    filter: contrast(1.4);
}

#faqBottom{
    width: 700px;
    position: absolute;
    left: 0;
    right: 0;
    filter: contrast(1.4);
    bottom: -50px;
    margin: auto;
    z-index: 10; 
}

#faqBannerSection{
    margin-top: 50px;
}

#faqBanner{
    width: 350px;
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    margin: auto;
}

#faqBannerSection h1{
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    margin: auto;
    color: #ddc4b0;
    text-shadow: 0 0 9px #876826;
}
#mintCount{
    position: absolute;
    width: 100%;
    bottom: 30px;
    padding: 0 !important;
    left: 0;
}


.progress-bar {
    width: 100%;
    background-color: #221102;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, .2);
}

.progress-bar-fill {
    display: block;
    height: 10px;
    background-color: #cca997;
    transition: width 1500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

#mintCountDesc{
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
}

.shake-slow-animation{
    animation: shake-slow-animation 2s cubic-bezier(.36,.07,.19,.97) both infinite;
}
@keyframes shake-slow-animation {
    5%, 45% {
        transform: translate3d(-1px, 0, 0);
    }
    10%, 40% {
        transform: translate3d(2px, 0, 0);
    }
    15%, 25%, 35% {
        transform: translate3d(-4px, 0, 0);
    }
    20%, 30% {
        transform: translate3d(4px, 0, 0);
    }
}


#nearPriceSpan{
    padding: 5px 15px;
    background: #4e1f09;
    box-shadow: 4px 4px #321000;
}
#sideNav{
    overflow: auto;
}